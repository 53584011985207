






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { format } from 'quasar'
import { tasksStore } from '@/store'

const { capitalize } = format

interface ListOption {
  value: number | null;
  importanceLevel: number;
  label: string;
}

@Component({
  name: 'SelectorList',
  components: {
    TaskImportanceIcon: () => import('@/components/Tasks/TaskImportanceIcon.vue'),
  },
})
export default class SelectorList extends Vue {
  @Prop({ type: Number, default: null }) readonly value!: number

  get listOptions (): ListOption[] {
    const levelCaptions = ['lowest', 'low', 'medium', 'high', 'highest']

    const mapper = (
      importance: number,
      importanceLevel: number,
    ): ListOption => {
      const c = levelCaptions[importanceLevel]
      const label = c
        ? capitalize(this.$t(`glossary.importance_${c}`).toString())
        : tasksStore.getters.importanceLabel(this.value)
      return {
        value: importance,
        importanceLevel,
        label,
      }
    }

    const options = tasksStore.getters.importanceOptions.map(mapper)

    options.unshift({
      value: null,
      label: this.$t('common.no').toString(),
      importanceLevel: -1,
    })

    return options.reverse()
  }
}
